<!--  -->
<template>
  <div class="main">
    <Headers  />
    <el-main class="ui-view">
      <router-view></router-view>
    </el-main>
  </div>
</template>
<script>
import '../../assets/css/reset.css'
import Headers from '../../components/Header'
export default {
  components:{
    Headers
  },
  data() {
      return {
          router: true,
          collapsed: true,
          activeIndex: 1, //默认颜色,
          navActive: 1,
          defaultActive: 'pinpai-graph',
          defaultOpeneds: ['1', '1-1', '3', '2', '2-1', '6']
      }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（访问DOM元素）,函数调用
  mounted() {

  },
  //计算属性
  computed: {},
  //函数
  methods: {
      selectmenu(index, indexPath) {
          this.activeMenu = index
          window.localStorage.setItem('activeMenu', this.activeMenu)
      },
      onNavSwitch() {
          this.isCollapse = !this.isCollapse
      },
      handleOpen(key, keyPath) {
          console.log(key, keyPath)
      },
      handleClose(key, keyPath) {
          console.log(key, keyPath)
      },


      // //左侧导航的判断     
      // onNavAside() {
      //     console.log( this.$route.path )
      //     let route_path = this.$route.path
      //     if (route_path == '/2-Gray-Model') {
      //         this.navActive = 3
      //         this.activeIndex = 3   //  设置顶部导航的active 的方式——获取与设置，然后走重新渲染即可！ 直接需改数据，貌似不能够重新渲染~
      //         this.defaultActive = 'Algorithms-Overview'
      //         console.log( this.$route.path )
      //     }

      // },
  },
  //监控
  watch: {}
}
</script>
<style scoped>
.main {
  height: 100%;
}

.el-container {
  width: 100%;
  height: 100%;
}

.ui-view {
  width: 100%;
  height: calc(100vh - 62px);
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

</style>
